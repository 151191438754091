// Header.js
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Header = () => {
  return (
    <header id="header" class="fixed-top bg-dark">
      <div class="container d-flex align-items-center">
        <h1 class="logo me-auto">
          <a href="https://sixthsens.ai/index.html">SixthSens AI</a>
        </h1>

        <nav id="navbar" class="navbar">
          <ul>
            <li class="dropdown">
              <a href="#partnerships">
                <span>Partners</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="https://sixthsens.ai/partners/google_cloud.html">
                    Google Cloud
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/partners/aws.html">AWS</a>
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a href="#products">
                <span>Products</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="https://recruitbots.ai/">
                    Recruitbots
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/products/analyseai">QuickDash</a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/products/aicopilot">AI Copilot</a>
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a href="">
                <span>Applications</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li class="dropdown">
                  <a href="">
                    <span>Solutions</span>
                    <i class="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="https://sixthsens.ai/solutions/vision_ai.html">
                        Vision AI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/solutions/voice_ai.html">
                        Voice AI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/solutions/text_ai.html">
                        Text AI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/solutions/generative_ai.html">
                        Generative AI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/solutions/automation.html">
                        Automation
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/solutions/data_intelligence.html">
                        Data Intelligence
                      </a>
                    </li>
                  </ul>
                </li>

                <li class="dropdown">
                  <a href="#casestudies">
                    <span>Case Studies</span> <i class="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/automated_gas_refilling.html">
                        Automated Gas Refilling through Machine Vision
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/streamlined_supply_chain.html">
                        Streamlined Supply Chain Financing Process
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/automating_lpg_cylinder.html">
                        Automating LPG Cylinder Handling with AI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/enhancing_worker_safety.html">
                        Enhancing Worker Safety with Image Analysis
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/transforming_exercise_experience.html">
                        Transforming Exercise Experience with Mixed Reality
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/extracting_insights.html">
                        Extracting Insights from Medical Big Data
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/empowering_leadership_development.html">
                        Empowering Leadership Development with Data Model & BI
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/revolutionizing_course.html">
                        Revolutionizing Course Discovery with Mobile App
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/predictive_data_models.html">
                        Predictive Data Models for BPO Call Center
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/ai_powered_sales.html">
                        AI-powered Sales Platform for Personalized Pitches
                      </a>
                    </li>
                    <li>
                      <a href="https://sixthsens.ai/case_studies/streamlining_manufacturing.html">
                        Streamlining Manufacturing Security with Nvidia Tesla
                        V100 GPUs
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a href="#industry4">
                <span>Industries</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href="https://sixthsens.ai/industry/edTech.html">EdTech</a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/manufacturing.html">
                    Manufacturing
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/public_sector.html">
                    Public Sector
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/sports.html">Sports</a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/rcpg.html">
                    Retail & CPG
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/media_environment.html">
                    Media & Entertainment
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/health_life_sciences_sector.html">
                    Health and Life Sciences
                  </a>
                </li>
                <li>
                  <a href="https://sixthsens.ai/industry/bfsi.html">
                    Banking and Financial Sector
                  </a>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="">
                <span>Company</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a class="nav-link scrollto" href="#footer">
                    Contact
                  </a>
                </li>
              </ul>
            </li>
            <li>
            <a class="nav-link scrollto" href="https://sixthsens.ai/careers">
                Careers
              </a>
            </li>
            <li>
              <a class="getstarted scrollto" href="https://app.sixthsens.ai/">
                Explore Marketplace
              </a>
            </li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
